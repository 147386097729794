export const updates = [
  {
    href: "peh-launch",
    date: "07/01/2025",
    hebTitle: "טכנולוגיות חדשות בארון הספרים היהודי!",
    hebText: `<p>אנחנו בדיקטה, נרגשים לחשוף את הפיתוחים האחרונים שלנו ביצירת טכנולוגיות שמטרתן לשלב בינה מלאכותית (AI) בארון הספרים היהודי.</p>
        <p>פיתוחים אלו הם בשׂורה גדולה לקהילת הלומדים, מאחר שהבינה המלאכותית משפרת משמעותית את קריאת הטקסטים והבנתם, ותורמת ליצירת קישורים מועילים ביניהם.</p>
        <p>בעזרת טכנולוגיות שפיתחנו המבוססות בינה מלאכותית יצרנו מראי מקום באופן אוטומטי, ולכן היקף הקישורים שאנו מציעים גדל באופן מהיר עם כל ספר שנכנס לספרייה הדיגיטלית שלנו.</p>
        <p>הודות ליישום חכם של בינה מלאכותית, יצרנו באופן אוטומטי סיכומים המאפשרים סקירה מהירה של תוכן הדף. כך הלומד יכול לקבל סיכום קצר של הדף ולא רק מראה מקום, שלעיתים אינו מספיק כדי לדעת מהו תוכן הדף עצמו. נוסף על כך, יצירת דפי אינדקס אוטומטיים תורמת לניווט יעיל בין נושאים כמו גם להעמקה בנושא מסוים.</p>
        <p>כל אלו מתאפשרים הודות לטכנולוגיית הסריקה של דיקטה ומודלי שפה מבוססי בינה מלאכותית שממירים טקסט מודפס לטקסט דיגיטלי. מלבד זה, גם טכנולוגיות אחרות שפותחו אצלנו בדיקטה כגון ניקוד אוטומטי ופתיחת ראשי תיבות אוטומטית, תורמים לחדשנות במפעל ארון הספרים היהודי.</p>
        <p>רוצים ליהנות מכל זה?</p>
        לאחרונה השקנו בדיקטה את המהדורה הדיגיטלית הראשונה מסוגה של שו"ת "פרי עץ חיים". זהו מסמך היסטורי מרתק בעל ערך הלכתי, שנדפס בשנים 1808-1728, על ידי חכמי ישיבת עץ חיים שבאמסטרדם. הנגשת השו"ת התאפשרה הודות ליישומי הבינה המלאכותית שהזכרנו. המהדורה זמינה בקישור:
        </p>
        <p><a href="https://peh.dicta.org.il/">https://peh.dicta.org.il/</a></p>
         <img src="/peh-launch.jpg" alt="Pri Etz Haim Launch"/>`,
    engTitle: "Revolutionizing the Jewish Bookshelf with AI Technology!",
    engText: `<p>At Dicta, we are thrilled to unveil our latest advancements in integrating artificial intelligence (AI) into the Jewish bookshelf.</p>
<p>These developments are a major breakthrough for the learning community, as AI significantly enhances text reading and comprehension, while also fostering meaningful connections between texts.</p>
<p>Using our AI-based technologies, we have developed an automatic cross-referencing system, which rapidly increases the number of links we offer with every new book added to our digital library.</p>
<p>Thanks to the smart application of AI, we have also created automatic summaries that provide a quick overview of page content. This allows learners to gain a concise understanding of the page, beyond just cross-references, which may not always suffice to grasp the content. Additionally, the creation of automatic index pages enables efficient navigation between topics as well as deeper exploration of specific subjects.</p>
<p>All of this is made possible through Dicta’s advanced scanning technologies and AI-driven language models that convert printed text into digital text. Furthermore, other innovations developed by Dicta, such as automatic vocalization and automated acronym expansion, contribute to the modernization of the Jewish bookshelf.</p>
<p>Want to experience it all?</p>
<p>We recently launched Dicta’s first-ever digital edition of the responsa <i>Pri Etz Haim</i>. This fascinating historical document, rich in halachic value, was printed between 1728 and 1808 by the sages of the Etz Haim Yeshiva in Amsterdam. Its accessibility was made possible through the AI applications mentioned above. The edition is available at the following link:</p>
<p><a href="https://peh.dicta.org.il/">https://peh.dicta.org.il/</a></p>
<img src="/peh-launch.jpg" alt="Pri Etz Haim Launch"/>
`,
  },
  {
    href: "library-700-books",
    date: "17/11/2024",
    hebTitle: "הגענו ל-700 ספרים בספרייה.",
    hebText: `<p>קישור לספרייה: <a href="https://library.dicta.org.il/">https://library.dicta.org.il/</a></p>
        <p>הינה כמה ספרים שתוכלו למצוא עתה בספרייה:</p>
       <p>1. שפתי כהן על התורה - רבי מרדכי הכהן, מחבר הספר, חי במפנה במאות 17-16. ספרו הוא פירוש על התורה גם על פי דרך הרמז והסוד. התגורר בצפת, ולמד שם מפי רבי יוסף קארו, רבי ישראל די קוריאל ורבי משה מטראני. בהמשך גורש ונדד לארם צובא. הספר הודפס לראשונה בחיי המחבר בוונציה בשנת ה'שס"ה (1605), ומאז הודפס במספר מהדורות נוספות. הכינוי 'שפתי כהן' גורם לרבים לבלבל בינו ובין חיבורו של רבי שבתי כהן, מחבר הפירוש המפורסם על חלקי חושן משפט ויורה דעה בשולחן ערוך.</p>
       <p>2. כלי חמדה - פירוש על התורה לפי סדר פרשות השבוע, הוא חיבורו השני של הרב מאיר דן רפאל פלוצקי, מרבני יהדות פולין בתחילת המאה ה-20, ממייסדי אגודת ישראל בפולין וחבר מועצת גדולי התורה.
דרכו של ר' מאיר דן בספר הזה היא לפרש את פסוקי התורה בדרך הפלפול התלמודי, והוא עוסק רבות בנושאים הלכתיים המתקשרים לפי דרכו לעניני הפרשה.
</p>
        <img src="/dicta-library-700.jpg" alt="Library 700 Books"/>`,
    engTitle: "Dicta Library has Reached 700 Books",
    engText: `<p>Link to the library: <a href="https://library.dicta.org.il/">https://library.dicta.org.il/</a></p>
<p>Here are some books you can currently find in the library:</p>
<p>Siftei Kohen on the Torah - Rabbi Mordechai HaCohen, the author of this work, lived during the late 16th and early 17th centuries. His book is a commentary on the Torah, incorporating interpretations based on hints (remez) and mystical secrets (sod). He resided in Safed and studied there under Rabbi Yosef Karo, Rabbi Yisrael Di Kuriel, and Rabbi Moshe Mitrani. Later, he was expelled and wandered to Aram Tzova (Aleppo). The book was first printed during his lifetime in Venice in the year 5365 (1605) and has since been reprinted in several additional editions. The title "Siftei Kohen" has led many to confuse it with the work of Rabbi Shabtai HaCohen, who authored the renowned commentary on sections of the Choshen Mishpat and Yoreh De'ah in the Shulchan Aruch.</p>
<p>Kli Chemdah - This commentary on the Torah, arranged according to the weekly Torah portions, is the second work by Rabbi Meir Dan Raphael Plotzki. He was a prominent rabbi in early 20th-century Poland, one of the founders of the Agudat Yisrael movement in Poland, and a member of the Council of Torah Sages (Moetzet Gedolei HaTorah).
<br>Rabbi Meir Dan's approach in this book involves interpreting Torah verses through the lens of Talmudic analysis (pilpul). He extensively discusses halachic (Jewish legal) issues that, in his methodology, connect to the themes of the Torah portion.</p>
<img src="/dicta-library-700.jpg" alt="Library 700 Books"/>`,
  },
  {
    href: "talmud-search-update",
    date: "06/06/2024",
    hebTitle: "התחדשנו בגרסה עדכנית לחיפוש בתלמוד!",
    hebText: `<p>הגרסה החדשה כוללת הרחבה של תוצאות החיפוש. הינה הצעות לתוצאות חיפוש שלא נכללו בגרסה הקודמת:</p>
    <ul><li>
    <a href="https://talmudsearch.dicta.org.il/result?text=%D7%97%D7%99%D7%99%D7%91%20%D7%90%D7%99%D7%A0%D7%99%D7%A9%20%D7%9C%D7%91%D7%A1%D7%95%D7%9E%D7%99">חייב איניש לבסומי</a>
    </li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=36%20%D7%A6%D7%93%D7%99%D7%A7%D7%99%D7%9D">36 צדיקים</a>
    </li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=%D7%95%D7%99%D7%94%D7%90%20%D7%9E%D7%95%D7%A8%D7%90%20%D7%A9%D7%9E%D7%99%D7%9D%20%D7%A2%D7%9C%D7%99%D7%9B%D7%9D%20%D7%9B%D7%9E%D7%95%D7%A8%D7%90%20%D7%91%D7%A9%D7%A8%20%D7%95%D7%93%D7%9D">ויהא מורא שמים עליכם כמורא בשר ודם</a></li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=%D7%9C%D7%99%D7%A9%20%D7%95%D7%9C%D7%91%D7%99%D7%90">ליש ולביא</a></li>
    </ul>
    <img src="/talmud-search-update.jpg" alt="talmud search updates"></a>
    `,
    engTitle: "New version of Talmud Search",
    engText: `<p>The new version includes an extension of search results. Here are several suggestions for search results that were not included in the previous version:</p>
    <ul><li>
    <a href="https://talmudsearch.dicta.org.il/result?text=%D7%97%D7%99%D7%99%D7%91%20%D7%90%D7%99%D7%A0%D7%99%D7%A9%20%D7%9C%D7%91%D7%A1%D7%95%D7%9E%D7%99">חייב איניש לבסומי</a>
    </li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=36%20%D7%A6%D7%93%D7%99%D7%A7%D7%99%D7%9D">36 צדיקים</a>
    </li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=%D7%95%D7%99%D7%94%D7%90%20%D7%9E%D7%95%D7%A8%D7%90%20%D7%A9%D7%9E%D7%99%D7%9D%20%D7%A2%D7%9C%D7%99%D7%9B%D7%9D%20%D7%9B%D7%9E%D7%95%D7%A8%D7%90%20%D7%91%D7%A9%D7%A8%20%D7%95%D7%93%D7%9D">ויהא מורא שמים עליכם כמורא בשר ודם</a></li>
    <li><a href="https://talmudsearch.dicta.org.il/result?text=%D7%9C%D7%99%D7%A9%20%D7%95%D7%9C%D7%91%D7%99%D7%90">ליש ולביא</a></li>
    </ul>
    <img src="/talmud-search-update.jpg" alt="talmud search updates"></a>`,
  },
  {
    href: "dicta-llm-release",
    date: "16/05/2024",
    hebTitle: "פריצת דרך במודל שפה גנרטיבי בעברית!",
    hebText: `<p>מפא"ת בשיתוף דיקטה והאיגוד הישראלי לשפות אנוש גאים להציג מודל שפה גנרטיבי גדול וחדש בעברית- Dicta-LM 2.0.</p>
    <p>על פי בדיקות רבות שנעשו המודל נחשב לאחד מה-LLM הטובים ביותר בעברית בקטגוריית מודלי שפה בגודל של עד 13 מיליארד פרמטרים!</p>
    <p>המודל זמין כעת להורדה חופשית ושימוש ללא הגבלה לאזרחי ישראל.</p>`,
    engTitle: "Breakthrough in Hebrew Generative Language Models!",
    engText: `<p>MAFAT, in collaboration with Dicta and the Israeli Association for Human Languages, proudly presents Dicta-LM 2.0, a large and advanced generative language model in Hebrew. Based on numerous tests, this model is considered one of the best in the category of language models with up to 13 billion parameters.</p>`,
  },
  {
    href: "shas-analytics",
    date: "05/12/2023",
    hebTitle: "הש״ס המנוקד עומד לרשות הציבור ללא כל מטרת רווח",
    hebText: `<p>זה שלוש שנים אנחנו, צוות דיקטה, עמלים על ניקוד הש"ס, ולשמחתנו השלמנו אותו לא מכבר, וכעת אפשר ליהנות ממסכתות מנוקדות.
        </p>
        <p>ניקוד הש״ס התאפשר הודות לנקדן הרבני מבית דיקטה. הנקדן הרבני הוא נקדן אוטומטי שמתחשב בכללי הניקוד הייחודיים לטקסטים רבניים מוקדמים ומאוחרים.
        </p>
        <p>
        בשלב הראשון נוקדו המסכתות ניקוד אוטומטי בנקדן הרבני, ובשלב השני ערך את הניקוד הרב יעקב לויפר בעזרת הנקדן.
        </p>
        <p>קישור לטקסט המלא בממשק של דיקטה: <a href="https://talmud.dicta.org.il/">https://talmud.dicta.org.il</a></p>
        <p>הקישור לטקסט המלא בממשק Github:<br><a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR1zUCx3jMXyKORzjNQPtI5u0vClAfVhrtbfC6LpTPGfB2LGYNLFisVcVfc/">https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR1zUCx3jMXyKORzjNQPtI5u0vClAfVhrtbfC6LpTPGfB2LGYNLFisVcVfc</a></p>
        <img src="/shas-menukad.jpg" alt="השס המנוקד"/>
        `,
    engTitle: "The vocalized Shas is now available to the public",
    engText: `
        <p>For the last three years, we at Dicta, been working on vocalizing the Shas, and now that the work is complete, you can enjoy viewing vocalized tracts.</p>
        <p>The vocalization of the Shas was made possible thanks to the Dicta's rabbinic Nakdan. The rabbinic Nakdan is an automatic tool that takes into account vocalization rules unique to early and later rabbinic texts.</p>
        <p>Initially, the tractates were automatically vocalized by the rabbinic Nakdan, after that, Rabbi Yaaqov Loyfer performed manual edits using the Nakdan.</p>
        <p>Link to the full text on Dicta's interface: <a href="https://talmud.dicta.org.il/">https://talmud.dicta.org.il</a></p>
        <p>Link to the full text on Githubg: <br><a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR1zUCx3jMXyKORzjNQPtI5u0vClAfVhrtbfC6LpTPGfB2LGYNLFisVcVfc/">https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR1zUCx3jMXyKORzjNQPtI5u0vClAfVhrtbfC6LpTPGfB2LGYNLFisVcVfc</a></p>
        <img src="/shas-menukad.jpg" alt="השס המנוקד"/>
        <img src="/shas-menukad.jpg" alt="vocalized Shas"/>`,
  },
  {
    href: "library-500",
    hebTitle: "הגענו ל־500 ספרים בספרייה",
    hebText: `<p>הינה כמה ספרים שתוכלו למצוא עתה בספרייה:</p>
        <p>1. אתוון דאורייתא הוא ספר למדני שחיבר הרב יוסף ענגיל, ראש ישיבה ורבה של העיר קראקא בפולין. הספר ממבשרי שיטת הלמדנות שכבשה את עולם הישיבות, והוא מורכב מ 27 'סימנים' שמנתחים שאלות עיוניות והלכתית באופן מקיף</p>
        <p>2. קובץ הערות הוא חיבורו החשוב ביותר של הרב אלחנן ווסרמן. זהו ספר שהמחבר הדפיס בעצמו (בשונה מ'קובץ שיעורים' שנדפס על ידי בנו). הספר נחשב עד היום 'נכסי צאן ברזל' בעולם הישיבות, ואין לומד של מסכת יבמות שאינו נזקק אליו.</p>
        <p>3. אגרת התשובה הוא אחד מחיבורי המוסר של רבי יונה גירונדי, בן המאה השתים עשרה, ממייסדי תפיסת המוסר של ימי הבינים (חיבורו המפורסם הוא 'שערי תשובה'). באיגרת הוא דן על היבטים שונים של מצוות, בעיקר אלו התלויות בלב, כמו תשובה, אהבת ה' ויראתו, וכיוצא בזה. הן מההיבט המוסרי והן מההיבט ההלכתי.</p>
        <p>קישור לספרייה: <a href="https://library.dicta.org.il/">https://library.dicta.org.il</a></p>
        <img src="/library-500.jpg"/ alt="הגענו ל־500 ספרים בספרייה">
        `,
    date: "09/07/2023",
    engTitle: "Dicta Library reaches 500 books",
    engText: `<p>Some of the new books include</p>
        <p>1. Atvon D'oratsa is a scholarly book authored by Rabbi Yosef Engel, a Rosh Yeshiva and Rabbi in Krakow, Poland. The book is one of the forerunners of the learning method that conquered the yeshiva world, and it consists of 27 'simanim' that comprehensively analyze theoretical and halachic questions.</p>
        <p>2. Kovetz He'arot (an assortment of notes) is Rabbi Elhanan Wasserman's most important composition. This is a book that the author printed himself (as opposed to 'Kovetz Shiurim', which was printed by his son). To this day, the book is considered an inalienable asset in the yeshiva world, and there are virtually no students of the Yevamot tract who do not use it.</p>
        <p>3. Egret Teshuvah is one of the moral works of Rabbi Yona Girondi, born in the twelfth century, one of the founders of the concept of morality in the Middle Ages (his famous work is 'Shaari Teshuvah'). In the letter he discusses various aspects of mitzvot, especially those that depend on the heart, such as repentance, love and fear of God, and the like. Both from the moral and the halachic aspect.</p>
        <img src="/library-500.jpg"/ alt="Dicta library 500 books milestone">`,
  },
  {
    href: "nakdan-live-with-morph",
    date: "05/09/2023",
    hebTitle: "הַנַּקְדָן הַמָּהִיר וְהַמֵּאִיר",
    hebText: `<p>גַּם מְנַקֵּד בְּעֵת הַקְלָדָה וְגַם מְסַיֵּעַ בִּבְחִירַת נִקּוּד מַתְאִים.</p>
        <p>חידושים אחרונים:<br>
        - סיוע בבחירת ניקוד מתאים בעזרת הבחנה מורפולוגית לאפשרויות ניקוד שנשמעות זהות
        <br>
        - חלוקה בין צורות שכיחות לשאינן. בלחיצה על החץ יוצגו כל אפשרויות הניקוד.
        </p>
        <p><a href="https://nakdanlive.dicta.org.il/">https://nakdanlive.dicta.org.il</a></p>
        <p>שתפו חברים!</p>
        <img src="/nakdan-live-morph.jpg" alt="Nakdan with morphology" />
        `,
    engTitle: "הַנַּקְדָן הַמָּהִיר וְהַמֵּאִיר",
    engText: `<p>When adding nikud, receive information that allows you to choose the most suitable option.</p>
        <p>Latest updates:<br>
        - View morphological information for nikud options.<br>
        - View only common results by default.
        </p>
        <p><a href="https://nakdanlive.dicta.org.il/">https://nakdanlive.dicta.org.il</a></p>
        <p>Share with friends!</p>
        <img src="/nakdan-live-morph.jpg" alt="Nakdan with morphology" />
        `,
  },
  {
    href: "haifa-nakdan-lecture",
    date: "23/08/2023",
    hebTitle: "פרסום חדש: הרצאתו של הרב לויפר על השס המנוקד",
    hebText: `
        <p>
        אוהבי ניקוד ודקדוק, או כאלו שזהו מקצועם, ימצאו לבטח ענין בתוכן ההרצאה על נקדן דיקטה, שנמסרה ע"י הרב יעקב לויפר במסגרת סדנה על מהדורות לספרות היהודית הקלאסית בעידן הדיגיטלי, שהתקיימה באוניברסיטת חיפה ביוני 2023.
        </p>
        <p>
        בהרצאה הוצגו האתגרים שעמם מתמודדים נקדני עברית החל מתקופת המצחפים המנוקדים הראשונים של התנ"ך, עבור דרך ספרות חז"ל וימי הביניים ועד ימינו. הודגמו גם כן יכולותיו של נקדן דיקטה המסתייע בבינה מלאכותית.
        </p>
        <p>
        את ההרצאה המלאה אפשר למצוא בעמוד הפרסומים בדף הבית.
        </p>
        `,
    engTitle: "Dicta Nakdan lecture",
    engText: `
        <p>
        Punctuation and grammar fans and professionals, will find interest in the content of the lecture on the Dicta Nakdan, delivered by Rabbi Yaaqov Loyfer as part of a workshop on editions of classical Jewish literature in the digital age, held at the University of Haifa in June 2023.
        </p>
        <p>
        In the lecture, the audience was presented with the challenges facing Hebrew vocalizers, starting from the time of the first punctuated frontispieces of the Bible, through the literature of the Sages and the Middle Ages to the present day. The capabilities of the Nakdan, using artificial intelligence were also demonstrated.
        </p>
        <p>
        The full lecture can be found on the Publication page of the Dicta website.
        </p>
        `,
  },
  {
    href: "medieval-nakdan",
    hebTitle: "מהיום אפשר לנקד כראוי את שירת ימי הביניים",
    hebText: `<p>
        בחירת תקופת השירה תאפשר לנקדן לבחור צורות ניקוד רלוונטיות לטקסט של המשתמש. קישור לנקדן המקצועי:
         <a href="https://nakdanpro.dicta.org.il/">https://nakdanpro.dicta.org.il</a>
        </p>
        <p>
         בדוגמה להלן ניתן לראות כיצד בניקוד ׳שירת ימי הביניים׳ הנקדן מנקד נכון, ואילו  במצב של ׳שירה מודרנית׳ הוא טועה בכמה מקומות.
        </p>
        <p>
         לדוגמה, המילה "כְּקֶשֶׁב" מנוקדת כשם עצם מופשט שעניינו הקשבה.
ההבדל הוא שבעברית מודרנית לא נוהגים להשתמש במילת היחס כ' לפני פועל, ואילו בשירת ימי הביניים זה רגיל עד מאוד.
        </p>
        <p>
        על כן, כאשר אנחנו במצב של ׳שירת ימי הביניים׳ הנקדן יעדיף לנקד צורות כמו "כקשב" כמילת היחס כ׳ ולאחר מכן פועל. לעומת זאת, ב׳שירה מודרנית׳, הנקדן יעדיף לנקד את המילה כשם עצם, שבו רלוונטי לשים כ' הדמיון.
        </p>
        <img src="/medieval-nakdan.jpg" alt="medieval nakdan"/>
        `,
    date: "30/07/2023",
    engTitle: "Add vocalization to medieval poetry",
    engText: `
        <p>
            The Nakdan Pro can now vocalize medieval texts. <a href="https://nakdanpro.dicta.org.il/">https://nakdanpro.dicta.org.il</a>
        </p>
        <p>In the image below, you can view the differences between medieval and modern poetry.</p>
        <p>The Nakdan is designed to apply different morphological rules to specific words depending on the chosen vocalization mode.</p>
        <img src="/medieval-nakdan.jpg" alt="medieval nakdan"/>
        `,
  },
  {
    href: "library-400",
    hebTitle: "הגענו ל־400 ספרים בספרייה",
    hebText: `<p>הינה כמה ספרים שתוכלו למצוא עתה בספרייה:</p>
        <p>1. חידושי הרי"מ לר' יצחק מאיר אלטר, מייסד שושלת גור - סרקנו את חידושי הרי"מ על השולחן ערוך (3 כרכים), שהם החידושים המוכרים פחות;</p>
        <p>2. מלאכת מחשבת לר' משה חפץ - פירוש על התורה שכתב לזכר בנו שנפטר במגפה בעודו נער ר"ל.</p>
        <p>קישור לספרייה: <a href="https://library.dicta.org.il/">https://library.dicta.org.il</a></p>
        <img src="/library-400.jpg"/ alt="הגענו ל־400 ספרים בספרייה">
        `,
    date: "09/07/2023",
    engTitle: "Dicta Library reaches 400 books",
    engText: `<p>Some of the new books include</p>
        <p>1. Chidushei Harim Levin. This three volume work by the Gur Chassidic Dynasty founder include lesser known topics.</p>
        <p>2. Milechet Machashevet by R' Moshe Hefetz - A commentary on the Torah that he wrote in memory of his young son.</p>
        <img src="/library-400.jpg"/ alt="Dicta library 400 books milestone">
        `,
  },
  {
    href: "dicta-analytics",
    hebTitle: "אנחנו שמחים לבשר על השקת הכלי אנליטיקס",
    hebText: `<p>
        הכלי מאפשר לשאול שאילתות על התנ"ך ועל טקסטים עבריים אחרים כדי לדלות נתונים כמו שכיחויות של שמות עצם, שמות פרטיים ועוד.
        <br>
        הכלי, המבוסס על ניתוח מורפולוגי, שמאפשר פילוח מדויק של הנתונים, הוא כלי פורץ דרך בתחומו.</p>
        <p><img src="/analytics.jpg" alt="Analytics image" /></p>
        <p>
        אנחנו משחררים בשמחה את הגרסה הראשונה לתנ"ך ולטקסטים עבריים אחרים.
        <br>
        אנליטיקס לתנ״ך: <a href="https://bibleanalytics.dicta.org.il/">https://bibleanalytics.dicta.org.il</a>
        <br>
        אנליטיקס לטקסטים עבריים: <a href="https://analytics.dicta.org.il/">https://analytics.dicta.org.il</a>
        </p>
        <p>הנה קישור לסרטון הסבר: <a href="http://y2u.be/f9EwCWvJJ-Y">http://y2u.be/f9EwCWvJJ-Y</a>
        `,
    engTitle: "New Analytics Tool Released",
    engText: `<p>
        The tool allows users to perform queries on the Bible and other Hebrew texts to extract data such as frequency of nouns, proper names, and more.
        <br>
        The tool, which is based on morphological analysis, enables accurate segmentation of the data, and is a groundbreaking tool in its field.
        </p>
        <p><img src="/analytics.jpg" alt="Analytics image" /></p>
        <p>
        We are happy to release the first version for the Bible and other Hebrew texts.
        <br>
        Bible Analytics: <a href="https://bibleanalytics.dicta.org.il/">https://bibleanalytics.dicta.org.il</a>
        <br>
        Hebrew Text Analytics: <a href="https://analytics.dicta.org.il/">https://analytics.dicta.org.il</a>
        </p>
        <p>Link to video tutorial (Hebrew): <a href="http://y2u.be/f9EwCWvJJ-Y">http://y2u.be/f9EwCWvJJ-Y</a>
        `,
    date: "31/05/2023",
  },
  {
    href: "synoptic-update",
    hebTitle: "עדכון לכלי להשוואת גרסאות!",
    hebText: `<p>
        עד היום הכלי תמך רק בטקסטים בגודל בינוני, ובמספר מוגבל של עדי נוסח, ואילו מהיום אפשר להריץ טקסטים ארוכים, באורך של ספר שלם, ועם מספר גדול של עדי נוסח, ללא הגבלה
        </p>
        <p>
        קישור לכלי: 
        <a href="https://synoptic.dicta.org.il/">https://synoptic.dicta.org.il</a>.
        </p>
        <img src="/synopsis-update.jpg" alt="עדכון לסינופסיס" />
        `,
    engTitle: "Update to the Synopsis tool",
    engText: `<p>
        Until today, the tool only supported medium sized texts, and a limited number of witness statements, now it is possible to run book length texts and unlimited witness statements.
        <p>
        <a href="https://synoptic.dicta.org.il/">https://synoptic.dicta.org.il</a>.
        </p>
        <img src="/synopsis-update.jpg" alt="Synopsis update" />
        </p>`,
    date: "24/05/2023",
  },
  {
    href: "charuzit-new-year",
    hebTitle: "חורזים ברכה לשנה החדשה?",
    hebText: `<p>
        או סתם מחפשים מילה מתאימה כדי לסגור את השורה?
        </p>
        <p>
        נסו את כלי החרוזית. הוא מדוייק, ידידותי, ועושה את העבודה.
        </p>
        <p>
        <a href="https://wordplay.dicta.org.il/">wordplay.dicta.org.il</a>.
        </p>
        <img src="/charuzit-new-year.jpg" alt="חרוזית שנה חדשה" />
        `,
    engTitle: "Rhymes for the New Year",
    engText: `<p>
        Try out Dicta's Charuzit. It is precise, user-friendly, and does the job.
        <p>
        <a href="https://wordplay.dicta.org.il/">wordplay.dicta.org.il</a>.
        </p>
        <img src="/charuzit-new-year.jpg" alt="חרוזית שנה חדשה" />
        </p>`,
    date: "24/05/2023",
  },
  {
    href: "dicta-library",
    hebTitle: "חדש חדש - הספרייה הדיגיטלית של דיקטה!",
    hebText: `<p>מיזם ייחודי שכולל למעלה ממאה ספרים תורניים שעברו סריקה אוטומטית. מיזם שהתאפשר הודות לטכנולוגיית AI פורצת דרך שפותחה בדיקטה ("דיקטה מייבין"), ומטרתה להנגיש את הטקסט הרבני-תורני.</p>
        <p>קישור לספרייה:
        <a href="https://library.dicta.org.il/">library.dicta.org.il</a>.
        </p>
        <p>
        הטקסטים כולם עברו עיבוד ולכן אפשר ליהנות מטקסט מנוקד, הרחבת ראשי תיבות, ציון מקורות ועוד.
        </p>
        <p>
        הטקסטים זמינים להורדה חינם.
        </p>
        <p>
        במהלך השנה הקרובה אנו מקווים להוסיף מאות ספרים חדשים למאגר.
        </p>
        <p>
        מוזמנים לבקר בספרייה ולשתף חברים כמובן.
        </p>
        <img src="/dicta-library.png" alt="Dicta Library" />
        `,
    engTitle: "NEW! Dicta's Digital Library",
    engText: `<p>A unique project that includes over a hundred automatically scanned Rabbinic-Torah Hebrew books. 
        A venture made possible thanks to the groundbreaking AI technology developed at Dicta ("Dicta Maivin"), and aims to make Rabbinic-Torah texts accessible to all.</p>
      
        <p>View the library here:
        <a href="https://library.dicta.org.il/">library.dicta.org.il</a>.
        </p>
        
        <p>
        The texts have all been processed, and can be viewed with vocalization, expanded abbreviations, and related sources.
        </p>
        <p>
        All texts can be downloaded free of charge.
        </p>
        <p>In the coming year, we hope to add more books to the collection.</p>
        <img src="/dicta-library.png" alt="Dicta Library" />
        `,
    date: "13/09/2022",
  },
  {
    href: "dicta-maivin",
    hebTitle: "דיקטה ״מֵייבִין״, עלה לאוויר!",
    hebText: `<p>מוזמנים לקרוא על הטכנולוגיה החדשה בראיון עם פרופ׳ משה קופל, ב 
        <a href="https://www.jpost.com/business-and-innovation/article-713942?utm_source=jpost.app.apple&utm_medium=share&fbclid=IwAR0BiCfw7oD-GgCGHbF5SjpKdPTCVS8XBvcXKxb-JBqMEYvARsgqTp3ulfs">"The Jerusalem Post"</a>.
        </p>`,
    engTitle: 'Dicta "Maivin" is Live!',
    engText: `<p>Learn more about Dicta "Maivin" in Professor Moshe Koppel's interview with <a href="https://www.jpost.com/business-and-innovation/article-713942?utm_source=jpost.app.apple&utm_medium=share&fbclid=IwAR0BiCfw7oD-GgCGHbF5SjpKdPTCVS8XBvcXKxb-JBqMEYvARsgqTp3ulfs">"The Jerusalem Post"</a>.</p>`,
    date: "14/08/2022",
  },
  {
    href: "dicta-bert",
    hebTitle: "בשורה משמחת לאנשי מדעי הרוח הדיגיטליים",
    hebText: `<p>קבלו נא מודל ברט ייעודי לטקסטים רבניים מבית דיקטה! אנחנו משחררים את המודל לנחלת הכלל, ומקווים שהוא יהיה לעזר לכל מי שמבקש לבנות מערכת שכרוכה בעיבוד שפה רבנית וטקסטים רבניים-תורניים.</p>
        <p>מאמר קצר על המודל וקישור להורדה: <a href="https://arxiv.org/abs/2208.01875">https://arxiv.org/abs/2208.01875</a>
        </p>`,
    engTitle: "BERT* model for Rabbinic Hebrew",
    engText: `<p>We've just released the world's first BERT* model for Rabbinic Hebrew, termed BEREL (BERT Encodings for Rabbinic-Encoded Language).</p>
<p>For the paper and link to download the model: <a href="https://arxiv.org/abs/2208.01875">https://arxiv.org/abs/2208.01875</a>.</p>
<p>*BERT models are pretrained contextualized language models, which form a key component in state-of-the-art natural language processing.</p>`,
    date: "04/08/2022",
  },
  {
    href: "dicta-remove-nikud",
    engTitle: "Want to remove nikud from a text?",
    engText: `
        <p>
        You can now do so with our new Remove nikud tool!
        </p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/F5O3qa1u4M0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

               <p>
Try it out - <br>
         <a href="https://removenikud.dicta.org.il/">https://removenikud.dicta.org.il</a>
        </p>
        
        `,
    hebTitle: "איך להעביר טקסט מכתיב חסר לכתיב מלא?",
    hebText: `
        <p>
עם כלי הסרת הניקוד שלנו, תוכלו לעשות זאת בקלות!
        </p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/F5O3qa1u4M0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p>
נסו גם אתם - <br>
         <a href="https://removenikud.dicta.org.il/">https://removenikud.dicta.org.il</a>
        </p>
       
        `,
    date: "11/05/2022",
  },
  {
    href: "dicta-nakdan-add-extension",
    engTitle:
      "Add vocalization (nikud) to text from all over the web - with a simple click of the mouse",
    engText: `
        <p>
        Introducing our new Chrome extension for adding vocalized text.
        </p>
         <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/flvFdloaEl0?playlist=flvFdloaEl0&showinfo=0&rel=0&mute=1&autoplay=1&loop=1&color=white"
                    frameborder="0" allowfullscreen title="YouTube video player"></iframe>
        <p>
        Link in Chrome store:
        <br>
        <a href="https://chrome.google.com/webstore/detail/dicta-nakdan-add-vocalize/pjjhddgjjmhbnlfjkobjmemihhbaaipd/related?hl=iw&fbclid=IwAR3uTwGdJXUKhqaQEtLx8wbIiWXQLMAOYJ2JtqgyxyQnF3rd-Tf9inXyBxk">
            https://chrome.google.com/webstore/detail/dicta-nakdan-add-vocalize...
        </a>
        </p>
        <p>Please make sure to leave your feedback in the comments section.</p>
        `,
    hebTitle: "מהיום אפשר להוסיף ניקוד לטקסט מכל מרחבי המרשתת - בלחיצת עכבר!",
    hebText: `
        <p>
הכירו את התוסף החדש שלנו לכרום (Chrome) להוספת ניקוד לטקסטים במרשתת.
        </p>
         <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/flvFdloaEl0?playlist=flvFdloaEl0&showinfo=0&rel=0&mute=1&autoplay=1&loop=1&color=white"
                    frameborder="0" allowfullscreen title="YouTube video player"></iframe>
        <p>
קישור לתוסף בחנות: <br>
         <a href="https://chrome.google.com/webstore/detail/dicta-nakdan-add-vocalize/pjjhddgjjmhbnlfjkobjmemihhbaaipd/related?hl=iw&fbclid=IwAR3uTwGdJXUKhqaQEtLx8wbIiWXQLMAOYJ2JtqgyxyQnF3rd-Tf9inXyBxk">
            https://chrome.google.com/webstore/detail/dicta-nakdan-add-vocalize...
        </a>
        </p>
        <p>
        ואל תשכחו לפרגן לנו משוב פה, או בחנות.
        </p>
        `,
    date: "30/12/2021",
  },
  {
    href: "dicta-nakdan-share-extension",
    engTitle:
      "Share vocalized text on Twitter, Facebook, or anywhere else across the web",
    engText: `
        <p>
        Introducing our new Chrome extension for sharing vocalized text.
        </p>
         <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/4HQTOHPSil8?playlist=4HQTOHPSil8&showinfo=0&rel=0&mute=1&autoplay=1&loop=1&color=white"
                    frameborder="0" allowfullscreen title="YouTube video player"></iframe>
        <p>
        Link in Chrome store:
        <br>
        <a href="https://chrome.google.com/webstore/detail/dicta-nakdan-share-vocali/mihlembodjlpghjcpedlefbambhmncpf?fbclid=IwAR2wRO2WOUlB7q-4kXxoQ2AtmXkWoJRW0wh019K7uBiKYp91cNcUZjU4eJ8">
            https://chrome.google.com/webstore/detail/dicta-nakdan-share-vocali...
        </a>
        </p>
        <p>Please make sure to leave your feedback in the comments section.</p>
        `,
    hebTitle:
      "מהיום אפשר לשתף טקסט מנוקד בפייסבוק, בטוויטר, או בכל מקום אחר במרחבי המרשתת",
    hebText: `
        <p>
        הכירו את התוסף החדש שלנו לכרום (Chrome) לניקוד טקסטים ושיתופם.
        </p>
         <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/4HQTOHPSil8?playlist=4HQTOHPSil8&showinfo=0&rel=0&mute=1&autoplay=1&loop=1&color=white"
                    frameborder="0" allowfullscreen title="YouTube video player"></iframe>
        <p>
קישור לתוסף בחנות: <br>
         <a href="https://chrome.google.com/webstore/detail/dicta-nakdan-share-vocali/mihlembodjlpghjcpedlefbambhmncpf?fbclid=IwAR2wRO2WOUlB7q-4kXxoQ2AtmXkWoJRW0wh019K7uBiKYp91cNcUZjU4eJ8">
            https://chrome.google.com/webstore/detail/dicta-nakdan-share-vocali...
        </a>
        </p>
        <p>
        ואל תשכחו לפרגן לנו משוב פה, או בחנות.
        </p>
        `,
    date: "30/12/2021",
  },
  {
    href: "tavnit-search",
    engTitle: "NEW: Search Words by Nikud",
    engText: `
        <p>
        Search for words according to nikud patterns. Searching for words with the nikud of ״מִרְפֶּסֶת״,
        and beginning with מ will return results such as: ״מִשְׁבֶּצֶת״, ״מִסְגֶּרֶת״
        </p>        
        Link to tool:
        <br/>
        <a href="https://wordplay.dicta.org.il/?type=tavnit">
            https://wordplay.dicta.org.il/?type=tavnit
        </a>
        </p>
        <p>
        Advanced features include: <br>
        - Select one or multiple nikud options for a letter<br>
        - Filter results by accent<br>
        - Filter results by morphological attributes<br>
        - Limit search to letters with a <i>dagesh</i> or root letters
        </p>
        <video style="width:50%;" controls autoplay muted loop><source src="tavnit.gif.mp4" type="video/mp4"></video>
        `,
    hebTitle: "חדש בדיקטה, כלי לאיתור מילים על פי ניקוד",
    hebText: `
        <p>
        מהיום תוכלו למצוא בקלות מילים שמתאימות לכל תבנית ניקוד, לדוגמה: אם תחפשו מילים זהות לתבנית הניקוד של ״מִרְפֶּסֶת״, שמתחילות באות מ, תקבלו תוצאות כמו ״  מִשְׁבֶּצֶת״, ״מִסְגֶּרֶת״ ועוד.
        </p>
        <p>
        קישור לכלי:
        <br/>
        <a href="https://wordplay.dicta.org.il/?type=tavnit">
            https://wordplay.dicta.org.il/?type=tavnit
        </a>
        </p>
        <p>
        דוגמאות לתכונות המתקדמות שתמצאו בכלי:
        <br/>
        - סימון ניקוד אחד ספיציפי לאות, או מבחר של סימני ניקוד אפשריים
        <br/>
        - סינון התוצאות לפי הטעמה
        <br/>
        - סינון התוצאות לפי תכונות מורפולוגיות (זכר/נקבה, יחיד/רבים, וכו')
        <br/>
        - הגבלת אותיות מסוימות לאותיות דגושות או לא דגושות וכן אותיות שורשיות או לא שורשיות
        <br>
        - ועוד
        </p>
        <p>
        מוזמנים לנסות את הכלי ולספר לנו על החוויה.
אנחנו מחכים לשמוע!
        </p>
        <video style="width:50%;" controls autoplay muted loop><source src="tavnit.gif.mp4" type="video/mp4"></video>
        `,
    date: "24/10/2021",
  },
  {
    href: "morph-analysis",
    engTitle: "Morphological Analysis of Hebrew Text",
    engText: `
        <p>New Morphology analysis tool released by Dicta
        <br>
        <a href="https://morph-analysis.dicta.org.il">
        https://morph-analysis.dicta.org.il
        </a>
        </p>
        <p>
        <img src="/morph-analysis.png" alt="Morphological Analysis" />
        </p>
        <p>Upload or copy text and receive a full analysis.
        <br>
        The results can be downloaded in BGU or UD format.
        </p>
        <p>
        Click below to listen to Dr. Avi Shmidman, Dicta's Research and Development Team leader, discuss the algorithm in use
        <br>
        <a href="https://slideslive.com/38928593/nakdan-professional-hebrew-diacritizer?fbclid=IwAR140IppNwEzmoHvvcWUv-_s1M-5z0Hm6T4NxUQLsohuDSsvHuycZFE3QNE" >
        https://slideslive.com/38928593/nakdan-professional-hebrew-diacritizer?fbclid=IwAR140IppNwEzmoHvvcWUv-_s1M-5z0Hm6T4NxUQLsohuDSsvHuycZFE3QNE
        </a>
        </p>
        <p>As always, we look forward to hearing your feedback for this tool.</p>
        `,
    hebTitle: "בשורה לעולם התיוג!",
    hebText: `
        <p>
        כלי חדש וחינמי לניתוח מורפולוגי מבית דיקטה.
        <br>
        קישור לכלי:
        <a href="https://morph-analysis.dicta.org.il">
        https://morph-analysis.dicta.org.il
        </a>
        </p>
        <p>
        <img src="/morph-analysis.png" alt="ניתוח מורפולוגי" />
        </p>
        <p>בעזרת הכלי תוכלו להעלות בקלות כל טקסט בעברית, ולקבל תוצאות הכוללות תיוג לכל מילה בטקסט.
        <br>
        באפשרותכם לבקש תוצאות בפורמט UD או BGU.
        </p>
        <p>
        כאן תוכלו לשמוע את ד״ר Avi Shmidman, ראש צוות מחקר ופיתוח בדיקטה, מדבר על האלגוריתם:
        <br>
        <a href="https://slideslive.com/38928593/nakdan-professional-hebrew-diacritizer?fbclid=IwAR140IppNwEzmoHvvcWUv-_s1M-5z0Hm6T4NxUQLsohuDSsvHuycZFE3QNE" >
        https://slideslive.com/38928593/nakdan-professional-hebrew-diacritizer?fbclid=IwAR140IppNwEzmoHvvcWUv-_s1M-5z0Hm6T4NxUQLsohuDSsvHuycZFE3QNE
        </a>
        </p>
        <p>
        יש לכם משוב? נשמח לשמוע!
        </p>
        `,
    date: "18/10/2021",
  },
  {
    href: "nakdan-app-update",
    engTitle: "Nakdan Live App Update",
    engText: `
        <p>We have just updated our Nakdan app. To download, click here:<br>
        <a href="https://play.google.com/store/apps/details?id=dicta.nakdan&fbclid=IwAR2yI6FtS39SWMpj5MZV-uY0wKoc1Eb8FpTjJyDjfwBmbEtdiF28CZc3ZZg/" >https://play.google.com/store/apps/details?id=dicta.nakdan&fbclid=IwAR2yI6FtS39SWMpj5MZV-uY0wKoc1Eb8FpTjJyDjfwBmbEtdiF28CZc3ZZg/</a>
        </p>
        <img src="/nakdan-app.png" alt="אפליקציה של  הנקדן" />
        <p class="mt-3">The app allows you to add nikud while typing and easily share your vocalized texts.</p>        
        `,
    hebTitle: "בשורה משמחת לחובבי האפליקציות!",
    hebText: `
        <p>
העלינו גרסה חדשה לאפליקציה החינמית של הנקדן.
קישור להורדת האפליקציה:
<br>
<a href="https://play.google.com/store/apps/details?id=dicta.nakdan&fbclid=IwAR2yI6FtS39SWMpj5MZV-uY0wKoc1Eb8FpTjJyDjfwBmbEtdiF28CZc3ZZg/" >https://play.google.com/store/apps/details?id=dicta.nakdan&fbclid=IwAR2yI6FtS39SWMpj5MZV-uY0wKoc1Eb8FpTjJyDjfwBmbEtdiF28CZc3ZZg/</a>
</p>
        <img src="/nakdan-app.png" alt="אפליקציה של  הנקדן" />
        <p class="mt-3">באפליקציה תוכלו לנקד טקסט תוך כדי הקלדה, ולשתף את הטקסט המנוקד בקלות.</p>
        `,
    date: "22/07/2021",
  },
  {
    href: "parallels-release",
    engTitle: "Find Parralels in Hebrew Texts",
    engText: `
        <a href="https://parallels.dicta.org.il/">https://parallels.dicta.org.il/</a></p>
        <p>The Parallels Finder tool finds sources that parallels text entered by the user. The tool is able to locate texts that have slight differences to the source text as well.</p>
        <p>The tool is in Alpha mode release. We look forward to hearing your feedback.</p>
        `,
    hebTitle: "כלי חדש חדש בדיקטה! כלי חינמי לאיתור מקבילות",
    hebText: `<p>
        <a href="https://parallels.dicta.org.il/" >https://parallels.dicta.org.il/</a></p>
        <p>הכלי לאיתור מקביליות נועד לאתר טקסטים המקבילים לטקסט המקור שהזנתם. הכלי מסוגל לאתר פסקאות מקבילות גם כאשר הפסקאות שונות זו מזו בנטיות הדקדוקיות של המילים, באותיות השימוש, וגם כאשר מילים אחדות הושמטו, נוספו, או הוחלפו.</p>
        <p>לדוגמה: טקסט הקלט הוא הספר "ארי נוהם" מאת יהודה אריה ממודינא. כאן מודינא מצטט משפט מאת ר' קורדווירו; אבל הוא לא מציין את שם הספר, אלא מסתפק באמירה שהמשפט נמצא "בחקרו". כלי המקבילות של דיקטה מגלה שמדובר בספר "פרדס רימונים", והפסקה המלאה מובאת מצד שמאל לרווחת הקורא.</p>
        <p>--- הכלי בפיתוח (גרסת אלפא), נשמח מאוד לקבל משוב!</p>
        <img src="/parallels.png" alt="איתור הקבלות בטקסטים" />
        `,
    date: "10/06/2021",
  },
  {
    href: "charuzit-hatmaa",
    engTitle: "Rhyming with Hebrew Accents",
    engText: `<p>
        Prevent Irrelevant Charuzit Results.
        </p>
        <p>
        New feature! Now you can filter results by Hebrew accents (מלעיל, מלרע).
        </p>
        <p>
        Link to our Charuzit tool: 
        <a href="https://wordplay.dicta.org.il/">https://wordplay.dicta.org.il</a>
        </p>
        <p>
        The accent settings are automatically configured according to the Hebrew search word.
        </p>
        <img src="/charuzit-hatmaa.jpg" alt="סינון חרוזים לפי הטמעה" />
        `,
    hebTitle: "חרוזים לפי הטעמה",
    hebText: `<p>
אוהבים לחרוז חרוזים, אבל נמאס לכם מחרוזים לא רלוונטיים?
        </p>
        <p>
        חדש חדש, מהיום תוכלו לסנן חרוזים לפי הטעמה - חרוזים במלעיל או במלרע.
        </p>
        <p>
        קישור לחרוזית שלנו: 
        <a href="https://wordplay.dicta.org.il/" >https://wordplay.dicta.org.il</a>
        </p>
        <p>אל תדאגו לא צריך להתאמץ הרבה - ברירת המחדל תתאים את ההטעמה למילת החיפוש שהזנתם.</p>
        <img src="/charuzit-hatmaa.jpg" alt="סינון חרוזים לפי הטמעה" />        
        `,
    date: "20/04/2021",
  },
  {
    href: "nakdan-live-mobile-share",
    engTitle: "Share Text with Nikud",
    engText: `<p>
        Now you can share your text with nikud on various platforms.
        <br/>
        Use the Nakdan Live to add and edit nikud to text as you type.
        <a href="https://nakdanlive.dicta.org.il/" >https://nakdanlive.dicta.org.il/</a>
        </p>
        <img src="/share-nikud.jpg" alt="Nakdan Live Share" />        
        `,
    hebTitle: "מֵהַיּוֹם אֶפְשָׁר לְשַׁתֵּף טֵקְסְט מְנֻקָּד בְּקַלּוּת",
    hebText: `<p>
        מֵהַיּוֹם אֶפְשָׁר לְשַׁתֵּף טֵקְסְט מְנֻקָּד בְּקַלּוּת בְּהוֹדָעוֹת וּבִרְשָׁתוֹת חֶבְרָתִיּוֹת:
        <a href="https://nakdanlive.dicta.org.il/" >https://nakdanlive.dicta.org.il/</a>
        </p>
        <img src="/share-nikud.jpg" alt="Nakdan Live Share" />        
        `,
    date: "18/04/2021",
  },
  {
    href: "remove-nikud-beta-version",
    engTitle: "Remove nikud and te'mim from text",
    engText: `<p>
        New tool available from Dicta:
        <br/>
        <a href="https://removenikud.dicta.org.il/" >https://removenikud.dicta.org.il/</a>
        </p>
        <p>Please share and send us your feedback.</p>
        <img src="/remove-nikud.png" alt="Remove Nikud" />
        `,
    hebTitle: "כלי להסרת ניקוד וטעמי המקרא מהטקסט!",
    hebText: `<p>
        כלי חדש וחינמי זמין במעבדה של דיקטה:
        <br/>
        <a href="https://removenikud.dicta.org.il/" >https://removenikud.dicta.org.il/</a>
        </p>
        <p>
        אנחנו מזמינים אתכם לתייג חברים, ולספק לנו משוב על השימוש בכלי.
        </p>
        <img src="/remove-nikud.png" alt="Remove Nikud" />
        `,
    date: "21/01/2021",
  },
  {
    href: "live-nakdan-beta-version",
    engTitle: "Add nikud as you type",
    engText: `<p>
        New live Nakdan that implements nikud as you type.
        <br/>
        <a href="https://nakdanlive.dicta.org.il/" >https://nakdanlive.dicta.org.il/</a>        
        </p>
        <p>
        Live nakdan special features:
        <ul>
            <li>Nikud is applied in real time while typing</li>
            <li>Text can be edited at any given time</li>
            <li>Nikud can easily be updated while you work</li>
        </ul>
        </p>
        <p>This tool is ideal for editing short texts or for looking up various nikud options for single words.</p>
        <p>As this tool is still in beta version, we look forward to hearing your input on it.
        </p>
        <img src="/live-nakdan.png" alt="Nakdan Live" />
        `,
    hebTitle: "נקדן מהיר ואינטואיטיבי שמנקד את הטקסט תוך כדי הקלדה",
    hebText: `<p>אנחנו בדיקטה משיקים נקדן מהיר וחינמי, לכבוד שבוע השפה העברית!
        <br/>
        נקדן מהיר ואינטואיטיבי שמנקד את הטקסט תוך כדי הקלדה.
        <br/>
        <a href="https://nakdanlive.dicta.org.il/" >https://nakdanlive.dicta.org.il/</a>
        </p>
        <p>
        מה החידוש בנקדן המהיר?
        <ul>
            <li>הניקוד מיושם תוך כדי הקלדה</li>
            <li>אפשר לערוך את הטקסט תוך כדי כתיבה</li>
            <li>עריכת הניקוד אינטואיטיבית ומהירה</li>
        </ul>
        </p>
        <p>
        אז אם יש לכם טקסט קצר לנקד, או סתם מילה שאתם רוצים לדעת איך לנקד, זה הכלי בשבילכם.
        </p>
        <pהכלי עדיין בגרסת בטא. אנחנו צריכים את המשוב שלכם, כדי להחליט על כיווני התפתחות.
        מוזמנים לנסות ולשתף חברים.</p>
        <img src="/live-nakdan.png" alt="Nakdan Live" />

        `,
    date: "07/01/2021",
  },

  {
    href: "top-phrases-new-beta-version",
    engTitle: "New tool in Dicta's lab!",
    engText: `<p>The Top Phrases tool organizes your Bible and Talmud searches. The tool groups results by phrases.
        <br/>
        Link to the tool: 
        <a href="https://top-phrases.dicta.org.il" />https://top-phrases.dicta.org.il</a>
        <br/>
        Link to tutorial (Hebrew):
        <a href="https://www.youtube.com/watch?v=2U6U4iZhR0I&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=5" />
        https://www.youtube.com/watch?v=2U6U4iZhR0I&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=5</a>
        </p>
        <p>The tools in our lab are Beta versions and have not yet been fully released. We therefore appreciate your input on these tools.</p>`,
    hebTitle: "כלי חדש במעבדה של דיקטה!",
    hebText: `<p>        
קצת על הכלי ועל המעבדה של דיקטה.
הכלי לחיפוש ביטויים עושה לכם סדר בתוצאות החיפוש של התנ״ך והתלמוד. הכלי מקבץ את תוצאות החיפוש לקבוצות של ביטויים.
        <br/>
        קישור לכלי: 
        <a href="https://top-phrases.dicta.org.il" />https://top-phrases.dicta.org.il</a>
        <br/>
        קישור לסרטון הדרכה:
        <a href="https://www.youtube.com/watch?v=2U6U4iZhR0I&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=5" />
        https://www.youtube.com/watch?v=2U6U4iZhR0I&list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X&index=5</a>
        </p>
        <p>
        הכלים במעבדה הם כלים בגירסת בטא שעדיין לא הגיעו לקו הגמר, ולכן חשוב לנו לקבל משוב מקדם.
שתפו.
        </p>
        `,
    date: "08/12/2020",
  },
  {
    href: "charuzit-mobile-tutorial",
    engTitle: "Hebrew tutorial for the mobile version of the Charuzit",
    engText: `
        <p>Click below to view a short demo.<br/>
        <a href="https://www.youtube.com/playlist?list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X" >https://www.youtube.com/playlist?list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X</a>         
        <img src="/charuzit-mobile-tutorial.png" alt="Charuzit mobile tutorial" />
        `,
    hebTitle: "סרטון הדרכה על אודות הכלי לאיתור חרוזים, בגירסה ייעודית לנייד!",
    hebText: `
        <p>מוזמנים לצפות בסרטון קצרצר בן 2 דקות, על אודות השימוש בכלי לאיתור חרוזים בנייד<br/>
        <a href="https://www.youtube.com/playlist?list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X" >https://www.youtube.com/playlist?list=PLEcDk-aPtpbbGvpbt32mq7SJTIVorOd2X</a>
        </p>        
        <img src="/charuzit-mobile-tutorial.png" alt="סרטון הדגמה לחרוזית" />
`,
    date: "25/10/20",
  },
  {
    href: "citation-tool-update",
    engTitle: "Update to Dicta's Citation tool",
    engText: `
        <p>The tool has been udpated to return more precise results and now includes an option to display only citations identical to the source text.
        <a href="https://citation.dicta.org.il/" >https://citation.dicta.org.il</a></p>
        
        <p>In the image below you can see an example of citations that are identical to the source text from 
        Rabbi Moshe Ben Ya'akov Adahan's poem: ״לכבוד תורה ההדורה״</p>
        <img src="/citation-update.png" alt="Citation Search Rabbi Adahan" />
        `,
    hebTitle: "ריעננו את הכלי לחיפוש איזכורים!",
    hebText: `
        <p>הכלי מציג תוצאות מדוייקות יותר, ונוסף על כך הוספנו אפשרות להציג רק מקורות שזהים לטקסט.
        מוזמנים לנסות:<br/>
        <a href="https://citation.dicta.org.il/" >https://citation.dicta.org.il</a><br/>        
        </p>        
        <p>בתמונה המצורפת ניתן לראות דוגמה למקורות שזהים לטקסט, מהפיוט ״לכבוד תורה ההדורה״ של ר' משה בן יעקב אדהאן.</p>
        <img src="/citation-update.png" alt="חיפוש אזכורים הרב אדהאן" />
`,
    date: "01/10/20",
  },
  {
    href: "search-nikud-launch",
    engTitle: "New Dicta Tool! Nikud Pattern Finder",
    engText: `
        <a href="https://nikudsearch.dicta.org.il/" >https://nikudsearch.dicta.org.il</a><br/>
        This free tool allows users to search various Nikud patterns in Tanach and Talmud. The tool is still in beta version.
        <p>Please send us your feedback!</p>
        <img src="/nikud-search-example.jpeg" alt="Nikud Search Image" />
        `,
    hebTitle: "כלי חדש בדיקטה! חיפוש תבנית ניקוד",
    hebText: `
        <a href="https://nikudsearch.dicta.org.il/" >https://nikudsearch.dicta.org.il</a><br/>
        הכלי חינמי ומאפשר לכם לחפש תבנית ניקוד בתנ״ך ובתלמוד.
        חשוב לציין שזוהי גירסת בטא, והמשוב שלכם חשוב לנו בהחלט לשם פיתוח הכלי.
        <p>שתפו אותנו!</p>
        <img src="/nikud-search-example.jpeg" alt="Nikud Search Image" />
`,
    date: "28/06/20",
  },
  {
    href: "automatic-nakdan-demo",
    engTitle: "Automatic Nakdan demo",
    engText: `
        First demo for the Automatic Nakdan<br/>        
        <br/><a href="https://www.youtube.com/watch?v=FkXcFUH0Ljg&feature=youtu.be&fbclid=IwAR0JkmnHBQ8J6MnfO-4YeTpGSBH_hxrKABZDodcxhFhUAZjSzOBzvcI2SaM" >View demo</a>
        `,
    hebTitle: "סרטון הדרכה על אודות הנקדן האוטומטי",
    hebText: `
        סרטון הדרכה ראשון, על אודות הנקדן האוטומטי מבית דיקטה!
<br/>מוזמנים לשתף :-)<br/><a href="https://www.youtube.com/watch?v=FkXcFUH0Ljg&feature=youtu.be&fbclid=IwAR0JkmnHBQ8J6MnfO-4YeTpGSBH_hxrKABZDodcxhFhUAZjSzOBzvcI2SaM" >לצפייה בסרטון</a>
`,
    date: "05/05/20",
  },
  {
    href: "search-update",
    engTitle: "Bible Search Upgrade",
    engText: `
        Improved search algorithm for higher accuracy results.<br/>
        We look forward to hearing your feedback:
        <br/><a href="https://search.dicta.org.il/?lang=en" >https://search.dicta.org.il</a>
        `,
    hebTitle: "השתדרגנו עם גירסה חדשה לחיפוש בתנ״ך!",
    hebText: `
כעת תוכלו לגלוש ביתר קלות ולקבל תוצאות ממוקדות יותר.
מוזמנים להשתמש בכלי ולשתף על אודות החוויה שלכם:
<br/><a href="https://search.dicta.org.il/" >https://search.dicta.org.il</a>
`,
    date: "05/03/20",
  },
  {
    href: "charuzit",
    engTitle: "New Charuzit Rhyming Tool",
    engText: `
        <p>Dicta's new Rhyming tool is now available (in Beta mode):<br/>
        <a href="https://wordplay.dicta.org.il" >https://wordplay.dicta.org.il</a></p>
        <p>What does the Charuzit offer?
        <ul>
        <li>Wide range of words.</li>
        <li>Filter results by Semantic words.</li>
        <li>Filter results by person, tense etc.</li>
        <li>Searh by Assonance and Alliteration.</li>
        <li>View Biblical results.</li>
        <li>Display results by categories such as: common words, long words, Biblical words, etc.</li>
        </ul>
        </p>
        <p>
        We look forward to hearing your feedback on the Charuzit.</br/>
        View a presentation of the Charuzit at Bar Ilan University: <a href="https://youtu.be/reYoyfcf3xU" >https://youtu.be/reYoyfcf3xU</a>
        </p>`,
    hebTitle: "קבלו את כלי החרוזית!",
    hebText: `<p>
כלי חדש בשלבי פיתוח: <a href="https://wordplay.dicta.org.il" >https://wordplay.dicta.org.il</a>
</p>
<p>
החרוזית הוא לא עוד כלי פשוט לאיתור חרוזים.
</br>
איזו בשורה הוא מביא?
<ul>
<li>מאגר מילים עשיר</li>
<li>סינון של תוצאות לפי שדה סמנטי רצוי</li>
<li>סינון של התוצאות לפני מאפיינים דקדוקיים: חלק דיבר, מין, זמן ועוד</li>
<li>איתור של אליטרציה ומצלול</li>
<li>חרוזים של מילים מהתנ״ך שמופיעים בתוך ההקשר של הפסוק</li>
<li>חלוקה של החרוזים לקטגוריות: מילים מהתנ״ך, מילים נפוצות, מילים ארוכות ועוד</li>
</ul>
</p>
<p>
נשמח לקבל משוב כדי לספק כלי מדוייק יותר לצרכים שלכם!</br>
לצפיה בהשקת הכלי בבר אילן: <a href="https://youtu.be/reYoyfcf3xU" >https://youtu.be/reYoyfcf3xU</a>
</p>
`,
    date: "16/01/20",
  },
  {
    href: "talmud-search-launch",
    engTitle: "Dicta Talmud Search tool Launch!",
    engText: `<p>As the Siyum of the  Daf Yomi approaches, Dicta is happy to announce the launch of a new Talmud Search tool.<br/>\
        <a href="https://talmudsearch.dicta.org.il" >https://talmudsearch.dicta.org.il</a></p>
        <p>The free Search engine is programmed to work with alternate spellings and multiple meanings.
        <br/>The tool is currently in development mode so we welcome any feedback to help us make this tool as efficent as possible.</p>
        <p>Search examples:<br/>
        Search: ״כל ישראל ערבים זה לזה״ Result: ״שכל ישראל ערבים זה בזה״<br/>
        Search: ״14 מוקף״ Results: ״בארביסר מוקפין״ and ״בארבעה עשר מוקפין״</p>`,
    hebTitle: "כלי חיפוש בתלמוד הושק בדיקטה!",
    hebText: `<p>
        לרגל סיום מחזור הדף היומי, אנחנו שמחים לבשר לכם על השקת כלי החיפוש בתלמוד.<br/>
        <a href="https://talmudsearch.dicta.org.il/he" >https://talmudsearch.dicta.org.il/he</a>
        </p>
        <p>
        מנוע החיפוש בתלמוד הוא עוד כלי חינמי מבית דיקטה, שמתחשב בהבדלי איות והטיה.
        הכלי בשלבי פיתוח ועל כן נשמח לקבל משוב כדי לייעל את הכלי.
        </p>
        <p>
        חיפושים לדוגמא:<br/>
        חפשו ״כל ישראל ערבים זה לזה״ וקבלו ״שכל ישראל ערבים זה בזה״<br/>
        חפשו ״14 מוקף״ וקבלו ״בארביסר מוקפין״ וגם ״בארבעה עשר מוקפין״</p>`,
    date: "19/12/19",
  },
  {
    href: "abbreviations-expander",
    engTitle: "Abbreviation Expander Launch",
    engText: `
        <p>
        Exciting news! <br/>
		Dicta released the Abbreviations Expander. A new and free tool that will significantly improve your learning experience.
        The tool detects and automatically expands Rabbinic abbrevations. <br/>
        Note that this is a beta version, so we look forward to receiving your feedback.<br/        
        <br/><a href="https://abbreviation.dicta.org.il/" >https://abbreviation.dicta.org.il</a></p>
        How does it work?
        <ul>
            <li>Enter Rabbinic text</li>
            <li>Result window will display original text along with expanded abbreviations</li>
            <li>Navigate between results using Left and Right keyboard arrow keys</li>
            <li>If there are multiple results for an abbreviation, navigate between them using the Up and Down arrow keys</li>
            <li>Manually edit selected abbreviation results</li>
        </ul>
        `,
    hebTitle: "פיענוח ראשי תיבות",
    hebText: `<p>
        בשורה משמחת לציבור הלומדים!<br/>
        פיענוח ראשי תיבות בטקסטים רבניים הינו כלי חדש וחינמי מבית דיקטה שעתיד לשפר משמעותית את חווית הלימוד.
        כלי פיענוח ראשי תיבות מאתר ומפענח ראשי תיבות בצורה אוטומטית.<br/>
        שימו לב שמדובר בגירסת בטא, ועל כן חשוב לנו לקבל משוב.<br/>
        <a href="https://abbreviation.dicta.org.il/" >https://abbreviation.dicta.org.il</a>
        </p>
        איך זה עובד?
        <ul>
            <li>הזינו טקסט רבני במקום המיועד לכך</li>
            <li>קבלו את הטקסט המקורי שלכם כולל פיענוח של ראשי התיבות שאותרו בטקסט</li>
            <li>עברו בין ראשי התיבות באמצעות חיצי המקלדת (שמאל וימין)</li>
            <li>בחרו באפשרות הפיענוח הרצויה: לכל ראשי תיבות קיימות מספר אפשרויות פיענוח שניתן לעבור ביניהן באמצעות חיצי המקלדת (למעלה ולמטה)</li>
            <li>ערכו את ראשי התיבות בצורה ידנית: בחרו את ראשי התיבות וערכו את הפיענוח בתיבה המיועדת לכך</li>
        </ul>
        <p>מוזמנים להשתמש ולשלוח משוב.</p>`,
    date: "17/12/19",
  },
  {
    href: "update-nakdan",
    engTitle: "New release of the Nakdan, new features!",
    engText: `<p>We are constantly enhancing the Nakdan: The latest version has several new features designed to increase your efficiency while working with the Nakdan.</p>
      <ul>
          <li>File upload: upload Word doc to the Nakdan and after adding nikud, download updated file.</li>
          <li>Morphological details: If you are debating between several nikud options, you can now view morphological details for each option in order to choose the most relevant niikud.</li>
          <li>Apply to entire text: When applying changes to nikud, choose between three possible methods:
              <ul>
                  <li>From current word onward</li>
                  <li>Only change unedited words</li>
                  <li>Change all words</li>                                                
              </ul>
          </li>    
          <li>Undo / Redo last action</li>
          <li>Edit nikud: Click on a word to see a list of possible nikud options on the left side of the screen. Scroll up and down the list using the Up and Down arrow keys.</li>
          <li>Move between words on the screen using the Right and Left arrow keys</li>
      </ul>`,
    hebTitle: "ושוב אנחנו מתחדשים עם הנקדן שלנו",
    hebText: `<p>הנקדן האוטומטי שלנו עולה שלב. להלן מספר עידכונים שתוכלו למצוא בנקדן, שמטרתם לייעל את עבודתכם:
        <br/>
            <a href="https://nakdan.dicta.org.il" >https://nakdan.dicta.org.il</a>
        </p>
        <ul>
            <li>העלאת קובץ: ניתן להעלות קובץ טקסט מסוג וורד (Word). בסיום עבודת הניקוד תוכלו להוריד חזרה את הקובץ.</li>
            <li>פרוט מורפולוגי: אם אתם מתלבטים בין חלופות אפשריות לניקוד, תוכלו לבקש להציג את הפרוט המורפולוגי כדי להכריע בין אפשרות אחת לאחרת.</li>
            <li>החל על כל הטקסט: שדרוג שיאפשר לכם לבחור בין 3 אפשרויות להחיל אפשרות ניקוד שבחרתם על כל המילים הזהות בטקסט: ממילה זו והלאה, רק על מילים שלא נערכו, על כל הטקסט.</li>
            <li>ביטול ושחזור פעולה אחרונה</li>
            <li>עריכת ניקוד: מספיק ללחוץ על המילה הרצויה ואפשרויות הניקוד יופיעו בצד שמאל. תוכלו להיעזר בחיצים למעלה ולמטה, כדי לעבור בין אפשרויות הניקוד</li>
            <li>מעבר בין מילים: לעבור בין המילים עם המקלדת, באמצעות החיצים ימין ושמאל.</li>
        </ul>`,
    date: "10/12/19",
  },
  {
    href: "update-citation",
    engTitle: "New! Citation Finder for Tanakh, Mishnah and Talmud",
    engText: `<p>Now it is possible to find citations from the Talmud, Mishnah and Tanakh in any given text.</p>
        <p>Using this tool you can easily find the correct sources used as a base for Rabinic texts.</p>
        <p>Try the tool here: <a href="https://citation.dicta.org.il/" >citation.dicta.org.il</a></p>
        <p>The following image displays citation results for the “שיטה מקובצת”  Using the Citation finder, you can locate the exact sources used in the work.</p>
        <img src="/citation-example.jpg" alt="Example of Citation finder results" />`,
    hebTitle: "חדש! איתור איזכורים לתנ״ך, לתלמוד ולמשנה בכלי אחד",
    hebText: `<p>הכלי נועד למצוא אזכורים בכל טקסט נתון. ועכשיו, הוספנו גם איתור מקורות מתוך המשנה והתלמוד.</p>
        <p>הכלי יכול לסייע לכם לאתר איזכורים בכל טקסט רבני. באופן הזה, תוכלו למצוא את המקורות המדוייקים עליהם הסתמכו המחברים בטקסטים רבניים.</p>
        <p>אתם מוזמנים לנסות בעצמכם: 
            <a href="https://citation.dicta.org.il/" >https://citation.dicta.org.il</a>
        </p>
        <p>הדוגמא הבאה לקוחה מתוך החיבור ״שיטה מקובצת״. עם הכלי שלנו תוכלו למצוא את המקורות המדויקים של האזכורים התלמודיים שמופיעים בחיבור.</p>
        <img src="/citation-example.jpg" alt="דוגמה לתוצאות חיפוש בכלי האזכורים" />`,
    date: "8/12/19",
  },
  {
    href: "update-berachot",
    engTitle: "Masechet Berachot Menukad Launch for Daf Yomi Siyum",
    engText: `<p>The nikud of Masechet Berachot is a massive undertaking made possible using Dicta’s (Rabbinic) Nakdan tool.
        </p>
        <p>The Rabbinic Nakdan is an automatic Nakdan which applies unique guidelines required for early and modern Rabinic texts.
        </p>
        <p>Masechet Berachot Menukad which has been vocalized in its entirety by the Nakdan and reviewed and edited by professional menakdim, is now available free of charge.
        </p>
        <p>Link to entire work on <a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR0mokirZfnpwcNha5OCrwz1_Vz2SQCXpdkDKf2AJSf4VxGoqM_PmYhWHLo" >Github</a>
        </p>
        <p>
            The work can also be accessed on the following sites:
            <ul>
                <li>Sefaria: <a href="https://www.sefaria.org.il" >https://www.sefaria.org.il</a></li>
                <li>Al Hatorah: <a href="https://alhatorah.org/" >https://alhatorah.org</a></li>
                <li>The Daf Yomi portal: <a href="https://www.daf-yomi.com" >https://www.daf-yomi.com</a></li>
            </ul>
        </p>
        <p>See Dicta’s home page for more information on the Nakdan and other Hebrew text tools</p>`,
    hebTitle: "מסכת ברכות המנוקד הושק לרגל סיום הדף היומי!",
    hebText: `<p>מסכת ברכות המנוקד, הוא מיזם שהתאפשר אודות לנקדן הרבני מבית דיקטה.</p>
        <p>הנקדן הרבני הינו נקדן אוטומטי שמתחשב בכללי הניקוד הייחודיים לטקסטים רבניים מוקדמים ומאוחרים.</p>
        <p>מסכת ברכות נוקדה במלואה בנקדן הרבני ועכשיו היא מופצת לתועלת כולם, ללא מטרות רווח. על הניקוד האוטומטי עברו מנקדים מקצועיים וערכו את הניקוד במידת הצורך, בעזרת הנקדן</p>
        <p>קישור לטקסט המלא ב :
            <a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud?fbclid=IwAR0mokirZfnpwcNha5OCrwz1_Vz2SQCXpdkDKf2AJSf4VxGoqM_PmYhWHLo" >
            Github
            </a>
        </p>
        <p>ניתן למצוא את הטקסט המנוקד, גם באחד מהמקומות הבאים:
            <ul>
                <li>ספריא: <a href="https://www.sefaria.org.il" >https://www.sefaria.org.il</a></li>
                <li>על התורה: <a href="https://alhatorah.org/" >https://alhatorah.org</a></li>
                <li>פורטל הדף היומי: <a href="https://www.daf-yomi.com" >https://www.daf-yomi.com</a></li>
            </ul>
        </p>
        <p>
            כמובן שאתם מוזמנים לבקר באתר הבית של Dicta דיקטה כדי להנות מכלים נוספים שישפרו את חווית הלמידה שלכם 
            <a href="http://dicta.org.il/index-he.html">http://dicta.org.il/index-he.html</a>
        </p>`,
    date: "01/12/19",
  },
  {
    href: "update-nakdan-oct",
    engTitle: "Nakdan Pro new version",
    engText: `<p>Following the launch of the Nakdan we are constantly upgrading its features based on feedback from users.</p>
        <p>The latest version can be found here: <a href="https://nakdanpro.dicta.org.il" >https://nakdanpro.dicta.org.il</a> and can also be accessed on Dicta’s home page.
            </p>
            <p>With the new version of Nakdan Pro you can now:
                <ul>
                    <li>Save your preferred nikud settings.</li>
                    <li>Mark words as invalid so they won’t appear as nikud options</li>
                    <li>Apply nikud either in full form (כתיב מלא) or shortened form (כתיב חסר)</li>
                    <li>Display קמץ קטן</li>
                    <li>Nikud of מה with פתח and דגש</li>
                    <li>Omit דגש</li>
                    <li>Copy and download files with semi vowels (אימות קריאה)</li>
                </ul>   
            </p>
            <p>In addition the new version of Nakdan pro has the following new features:
                <ul>
                    <li>Display of Biblical quotes using special Biblical font</li>
                    <li>Enhanced and improved nikud</li>
                    <li>Faster response times</li>
                    <li>New keyboard shortcuts including using arrow keys to move between areas on the results screen. A list of the available keyboard shortcuts appears on the top left of the screen.</li>
                </ul>`,
    hebTitle: "גירסה חדשה של הנקדן המקצועי עלתה לאויר!",
    hebText: ` <p>מאז השקת הנקדן המקצועי עמלנו על שידרוגים לנקדן, לפי המשוב שקיבלנו מכם.</p>
        <p>מצורף קישור לנקדן המקצועי (אפשר גם לגשת לנקדן דרך אתר הבית של דיקטה): 
            <a href="https://nakdanpro.dicta.org.il/" >https://nakdanpro.dicta.org.il</a>
        </p>
        <p>מהיום תוכלו להגדיר את ההעדפות שלכם:
            <ul>
                <li>אפשרות לסמן מילה כ״פגומה״ כך שלא תופיע באפשרויות הניקוד</li>
                <li>ניקוד בכתיב מלא</li>
                <li>הצגת קמץ קטן בתו המיוחד לכך</li>
                <li>ניקוד ״מה״ בפתח + דגש</li>
                <li>השמטת דגש כבמקורות</li>
                <li>אפשרות להעתיק / להוריד קובץ, עם אימות קריאה</li>
            </ul>
            כמו כן תוכלו ליהנות גם מהתכונות הבאות:
            <ul>
                <li>הצגת ציטוטים מהתנ״ך בפונט תנ״כי</li>
                <li>שיפורים רבים באיכות הניקוד</li>
                <li>מהירות תגובה גבוהה יותר</li>
                <li>קיצורי מקלדת חדשים. לדוגמה: מעבר בין שורות ופסקאות (הרשימה המלאה מוצגת תחת ״קיצורי מקלדת״ בחלק העליון בצד שמאל של המסך)</li>
            </ul>                                    
            </p>
            <p>קישור לאתר הדרכה: <a href="https://nakdanpro.dicta.org.il/documentation/" >https://nakdanpro.dicta.org.il/documentation/</a></p>
            <p>כמו כן, אנחנו ממשיכים לעבוד על דיוק התוצאות ושידרוגים נוספים לנקדן,</p>
        </p>`,
    date: "22/10/19",
  },
]
